/* Script of datalayer push for newsletter sidebar */
document.addEventListener("DOMContentLoaded", function () {
  const button = document.querySelector(".newsletter-submit");
  const emailField = document.getElementById("newsletter-email");
  const EmailLink = document.querySelector('a[href="mailto:newsletter@bildungsklick.de"]');

  button.addEventListener("click", function () {
    window.dataLayer = window.dataLayer || [];
    const emailValue = emailField.value.trim();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const checkboxes = document.querySelectorAll('.checkboxNewsletter input[type="checkbox"]');
    let checkedValues = [];
    let checkedStatus = false;
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        checkedValues.push(checkbox.value);
      }
    });
    checkedStatus = checkedValues.length > 0;
    const checkedResult = checkedValues.join(', ');

    if (!emailValue) {
      window.dataLayer.push({
        event: 'newsletter_interactions',
        checkbox_name: checkedResult,
        checkbox_checked: checkedStatus,
        status: 'raw attempt',
      });
    } else if (!emailRegex.test(emailValue)) {
      window.dataLayer.push({
        event: 'newsletter_interactions',
        checkbox_name: checkedResult,
        checkbox_checked: checkedStatus,
        status: 'raw attempt',
      });
    } else {
      window.dataLayer.push({
        event: 'newsletter_interactions',
        checkbox_name: checkedResult,
        checkbox_checked: checkedStatus,
        status: 'form submitted',
      });
    }
  });

  EmailLink.addEventListener("click", function (event) {
    event.preventDefault();
    window.dataLayer.push({
      event: 'newsletter_interactions',
      status: 'Unsubscribe',
    });
  });
});

// Footer Newsletter dataLayer push
document.addEventListener("DOMContentLoaded", function () {
  const button = document.querySelector(".newsletter-submit2");
  const emailField = document.getElementById("newsletter-email2");
  const EmailLink = document.querySelector('.bodytext-small a[href="mailto:newsletter@bildungsklick.de"]');

  button.addEventListener("click", function () {
    window.dataLayer = window.dataLayer || [];
    const emailValue = emailField.value.trim();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const checkboxes = document.querySelectorAll('.checkboxNewsletterFooter input[type="checkbox"]');
    let checkedValues = [];
    let checkedStatus = false;
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        checkedValues.push(checkbox.value);
      }
    });
    checkedStatus = checkedValues.length > 0;
    const checkedResult = checkedValues.join(', ');

    if (!emailValue) {
      window.dataLayer.push({
        event: 'newsletter_interactions',
        checkbox_name: checkedResult,
        checkbox_checked: checkedStatus,
        status: 'raw attempt',
      });
    } else if (!emailRegex.test(emailValue)) {
      window.dataLayer.push({
        event: 'newsletter_interactions',
        checkbox_name: checkedResult,
        checkbox_checked: checkedStatus,
        status: 'raw attempt',
      });
    } else {
      window.dataLayer.push({
        event: 'newsletter_interactions',
        checkbox_name: checkedResult,
        checkbox_checked: checkedStatus,
        status: 'form submitted',
      });
    }
  });
});

//Footer newsletter email click dataLayer push
document.addEventListener("DOMContentLoaded", function () {
  const parentElement = document.querySelector('.emailLinkFooter');
  if (parentElement) {
    parentElement.addEventListener('click', function (event) {
      const target = event.target;
      if (target.tagName === 'A' && target.href === 'mailto:newsletter@bildungsklick.de') {
        event.preventDefault();
        window.dataLayer.push({
          event: 'newsletter_interactions',
          status: 'Unsubscribe',
        });
        setTimeout(() => {
          window.location.href = target.href;
        }, 100);
      }
    });
  }
});

document.addEventListener("DOMContentLoaded", function () {
  if (document.body.id === "page_151") {
    //console.log("First Interecation");
    setTimeout(() => {
      datalayerSearchOnPageLoad();
    }, 1000);
  }
});

function datalayerSearchOnPageLoad() {
  //console.log("Starts the push");
  const resultCount = document.getElementById("sresultcount")?.textContent || null;
  const queryString = document.getElementById("squery")?.textContent || null;
  const urlParams = new URLSearchParams(window.location.search);
  const { isPaginationClicked, pageNumber } = checkPaginationClicked();

  // Get the current page's URL
  const currentUrl = window.location.href;
  const currentUrlParams = new URL(currentUrl).searchParams;
  const fullFilters = [];
  currentUrlParams.forEach((value, key) => {
    // Check if the key starts with 'tx_solr[filter]'
    if (key.startsWith("tx_solr[filter]")) {
      fullFilters.push(value);
    }
  });
  const lastFilter = fullFilters.pop();

  let ressorts = null;
  let state = null;
  let tags = null
  let dossiers = null;
  let issetRessorts = false, issetStates = false, issetTags = false, issetDossiers = false;

  urlParams.forEach((value, key) => {
    if (key.includes("filter")) {
      if (value.includes("ressorts:")) {
        ressorts = value.split("ressorts:")[1].split("+")[0];
        issetRessorts = true;
      }
      if (value.includes("state:")) {
        state = value.split("state:")[1].split("+")[0];
        issetStates = true;
      }
      if (value.includes("tags:")) {
        tags = value.split("tags:")[1].split("+")[0];
        issetTags = true;
      }
      if (value.includes("dossiers:")) {
        dossiers = value.split("dossiers:")[1].split("+")[0];
        issetDossiers = true;
      }
    }
  });
  let variableNames = [
    { name: "Ressorts", value: issetRessorts },
    { name: "State", value: issetStates },
    { name: "Tags", value: issetTags },
    { name: "Dossiers", value: issetDossiers }
  ];
  let selectedCategories = variableNames
    .filter(variable => variable.value) // Only keep variables with true value
    .map(variable => variable.name)    // Extract their names
    .join(", ");

  selectedCategories = selectedCategories || undefined;

  callDataLayerPush(queryString, resultCount, state, ressorts, tags, dossiers, selectedCategories, isPaginationClicked, pageNumber, lastFilter);
}

function callDataLayerPush (queryString, resultCount, state, ressorts, tags, dossiers, selectedCategories, isPaginationClicked, pageNumber, lastFilter) {
  const removeBtnStatus = sessionStorage.getItem('removeBtnStatus');
  const resetBtnStatus = sessionStorage.getItem('resetBtnStatus');
  pageNumber = pageNumber === null ? 1 : pageNumber;
  state = state === null ? undefined : state;
  ressorts = ressorts === null ? undefined : ressorts;
  tags = tags === null ? undefined : tags;
  dossiers = dossiers === null ? undefined : dossiers;
  const dataLayerObject = {
    event: 'search_interactions',
    search_term:queryString,
    selected_categorie: lastFilter, //selectedCategories,
    search_categories: {
      state: state,
      resort: ressorts,
      tags: tags,
      dossier: dossiers,
    },
    search_results_number: resultCount,
  };

  // For first load the search page
  if (selectedCategories === undefined) {
    dataLayerObject.action = 'search_performed';
    dataLayerObject.pagination = pageNumber;
  }
  // If a category is selected
  if (selectedCategories !== undefined) {
    dataLayerObject.action = 'select_category';
    dataLayerObject.pagination = pageNumber;
  }
  // If pagination is clicked
  if (isPaginationClicked === true) {
    dataLayerObject.action = 'pagination_change';
    dataLayerObject.pagination = pageNumber;
  }
  // If Category is removed
  if (removeBtnStatus !== "null" && removeBtnStatus !== null) {
    dataLayerObject.action = 'remove_filter';
    dataLayerObject.pagination = pageNumber;
    sessionStorage.setItem('removeBtnStatus', null);
  }
  // If reset button is clicked
  if (resetBtnStatus !== "null" && resetBtnStatus !== null) {
    dataLayerObject.action = 'reset_filter';
    dataLayerObject.pagination = null;
    sessionStorage.setItem('resetBtnStatus', null);
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(dataLayerObject);

  // If a filter is removed
  const RemoveButton = document.querySelector(".remove-facet-option");
  const ResetButton = document.querySelector("#tx-solr-facets-in-use .solr-ajaxified.btn.btn-dark");
  RemoveButton.addEventListener("click", function () {
    sessionStorage.setItem('removeBtnStatus', 'clicked');
  });

  // If a Reset Button is clicked
  ResetButton.addEventListener("click", function () {
    sessionStorage.setItem('resetBtnStatus', 'clicked');
  });
}

function checkPaginationClicked () {
  const url = new URL(window.location.href);
  const urlCheck = new URLSearchParams(url.search);
  const isPaginationClicked = [...urlCheck.keys()].some(key => key.includes('page'));
  const pageKey = [...urlCheck.keys()].find(key => key.includes('page'));
  const isPageAvailable = pageKey !== undefined;
  const pageNumber = isPageAvailable ? urlCheck.get(pageKey) : null;

  return { isPaginationClicked, pageNumber };
}


document.addEventListener("DOMContentLoaded", function () {
    const article = sessionStorage.getItem('article');
    const article_clicked = sessionStorage.getItem('article_clicked');
    const titles = document.querySelectorAll(".results-list h2.e-header-h2 a");
    if (article_clicked !== "null" && article_clicked !== null) {

      const dataLayerObject = {
        event: 'search_interactions',
        action: 'article click',
        article_name: article
      };
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(dataLayerObject);

      sessionStorage.setItem('article', null);
      sessionStorage.setItem('article_clicked', null);
    }

    titles.forEach(function (title) {
      title.addEventListener("click", function (event) {
        const clickedTitle = event.target.textContent.trim();
        sessionStorage.setItem('article', clickedTitle);
        sessionStorage.setItem('article_clicked', 'clicked');
      });
    });
});

