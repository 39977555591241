// import from 'bootstrap'
import {
  Modal,
  Offcanvas, // mobile Nav
  Tab,       // Tab CE
  Popover,
  Dropdown,
  Toast,
  Tooltip,
  Collapse,   // mobile Facets
  ScrollSpy,
  Button,
  Alert
} from 'bootstrap'

//require('jquery.mmenu/dist/jquery.mmenu.all');

//import MmenuLight from 'mmenu-light';

import SwiperInit from './js/swiper-init'
SwiperInit();

// import custom SCSS
import './scss/main.scss';

// Open burgermenu dropdown
document.querySelectorAll('.dropdown-arrow').forEach(function (element) {
    element.addEventListener('click', function () {
      const dropdownMenu = this.parentElement.nextElementSibling;
      if (dropdownMenu.classList.contains('show')) {
        dropdownMenu.style.maxHeight = dropdownMenu.scrollHeight + 'px';
        setTimeout(function() {
          dropdownMenu.style.maxHeight = '0';
        }, 10);
        dropdownMenu.classList.remove('show');
      } else {
        dropdownMenu.classList.add('show');
        dropdownMenu.style.maxHeight = '0';
        setTimeout(function() {
          dropdownMenu.style.maxHeight = dropdownMenu.scrollHeight + 'px';
        }, 10);
      }
    });
  });

  // Close dropdown if clicked outside
  document.addEventListener('click', function (e) {
    var isClickInside = document.querySelector('.dropdown').contains(e.target);
    if (!isClickInside) {
      document.querySelectorAll('.dropdown-menu').forEach(function (dropdown) {
        if (dropdown.classList.contains('show')) {
          dropdown.style.maxHeight = dropdown.scrollHeight + 'px';
          setTimeout(function() {
            dropdown.style.maxHeight = '0';
          }, 10);
          dropdown.classList.remove('show');
        }
      });
    }
  });

import './js/datalayer-push.js';

